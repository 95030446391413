'use client';

import type {
    BrandPageFragment,
    CampaignFragment,
    CategoryPageFragment,
    DesignerPageFragment,
    EditorialPageFragment,
    GenericPageFragment,
    ProductPageInfoFragment,
    ServicePageFragment,
} from '@server/gql/graphql';
import { useEffect } from 'react';

export default function InjectSessionPageSiblings({
    id,
    page,
}: {
    id?: string;
    page?:
        | BrandPageFragment
        | CampaignFragment
        | CategoryPageFragment
        | DesignerPageFragment
        | EditorialPageFragment
        | GenericPageFragment
        | ServicePageFragment
        | ProductPageInfoFragment
        | null;
}) {
    useEffect(() => {
        if (page) {
            switch (page.__typename) {
                case 'ProductPageInfo': {
                    window.sessionStorage.setItem('siblings', `product:${id}`);
                    break;
                }
                case 'Campaign':
                case 'EditorialPage':
                case 'Page': {
                    window.sessionStorage.setItem(
                        'siblings',
                        JSON.stringify({
                            sv: page.se_slug,
                            nb: page.no_slug,
                            da: page.dk_slug,
                            fi: page.fi_slug,
                            nl: page.nl_slug,
                            en: page.en_slug,
                        }),
                    );
                    break;
                }
                default:
                    break;
            }
        }
        return () => window.sessionStorage.removeItem('siblings');
    }, [id, page]);

    return null;
}
